@import 'constants.scss';

.modal {
  animation: fadeIn ease-in $TRANSITION_LONG both;
  z-index: $Z_INDEX_MODAL;

  // Covers the screen
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  // Centers contents
  display: flex;
  align-items: center;
  justify-content: center;
}

// Background overlay
.modalBackground {
  z-index: 1;

  // Covers the screen
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  background-color: black;
  opacity: 0.5;
}

.filler {
  height: 250px;
}

.header {
  font-size: 42px;
  text-align: center;
  color: #fff;
  text-transform: uppercase;
  letter-spacing: $LETTER_SPACING_LG;
  padding-top: 80px;
  font-weight: 700;
}

.modalContent {
  z-index: 2; // Show over the background overlay

  background-color: white;
  padding: $GUTTER_XL;

  // If it absolutely needs to, it should scroll
  overflow: auto;

  // On desktop, use a fixed width
  @media (min-width: $BREAKPOINT_SM + 1) {
    box-sizing: border-box;
    margin: $GUTTER_SM;
    position: relative;
    min-height: 250px;
    max-height: 90vh;
    width: 500px;
  }

  // On mobile, fill the screen
  @media (max-width: $BREAKPOINT_SM) {
    margin: 0;

    position: absolute;
    min-height: 0;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}

.fullWidthImage {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  height: 250px;
  background-position: center;
  background-size: cover;
}

.closeButton {
  fill: $COLOR_GRAY2;
  position: absolute;
  top: $GUTTER_LG;
  right: $GUTTER_LG;

  cursor: pointer;
  width: 32px;
  height: 32px;
}

.whiteCloseButton {
  fill: white;
  position: absolute;
  top: $GUTTER_LG;
  right: $GUTTER_LG;

  cursor: pointer;
  width: 32px;
  height: 32px;
}
