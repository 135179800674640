@import 'constants.scss';

.section2 {
  background-color: $COLOR_BG_LIGHT;
  font-size: 24px;
  text-align: center;
}

.section2,
.section3 {
  padding: $GUTTER_XXL;
}

.section3 {
  display: flex;
  margin: auto;
  max-width: $PAGE_WIDTH;
  .col {
    flex: 1;
    &:not(:last-child) {
      margin-right: $GUTTER_XXL;
    }
  }
  .title {
    margin-top: 0;
    margin-bottom: $GUTTER_LG;
    text-align: left;
  }
}

.section2 {
  display: flex;
  align-items: center;
  justify-content: center;
}

.callout {
  color: #333333;
  font-weight: bold;
  font-size: 42px;
  margin: $GUTTER_SM;
  text-transform: uppercase;
}

.leftLaurel,
.rightLaurel {
  width: 116px;
  height: 240px;
}

.rightLaurel {
  transform: scaleX(-1);
}

.learnMoreLink {
  margin-top: $GUTTER_LG;
}

.introVideo {
  width: 560px;
  height: 315px;
}

.purchaseAction,
.newStudentAction {
  margin-top: $GUTTER_XL;
  padding: $GUTTER_LG;
  &:not(:last-child) {
    margin-right: $GUTTER_LG;
  }
}

.purchaseAction {
  background: rgba($COLOR_ACTIVE, 0.9) !important;
}

.newStudentAction {
  background: rgba($COLOR_GRAY, 0.9) !important;
}

@media only screen and (max-width: $BREAKPOINT_MD) {
  .introVideo {
    width: 351px;
    height: 197px;
  }
}

@media only screen and (max-width: $BREAKPOINT_SM) {
  .videoContainer {
    margin-top: $GUTTER_XL;
    position: relative;
    padding-bottom: 50%;
    height: 0;
    overflow: hidden;
    .introVideo,
    object,
    embed {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  .section3 {
    display: block;
    padding: $GUTTER_XXL $GUTTER_MD;
    .col {
      width: 100%;
    }
    .title {
      text-align: center;
    }
  }
}
