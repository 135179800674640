@import 'constants.scss';

.actionLink {
  color: white;
  display: inline-block;
  font-family: $FONT_FAMILY;
  font-size: $FONT_SIZE_LG;
  font-weight: bold;
  padding: $GUTTER_MD $GUTTER_XL;
  text-decoration: none;
  text-transform: uppercase;
  text-align: center;

  transition: background-color $TRANSITION_LONG;

  &.outline {
    background-color: transparent;
    border: 2px solid white;
  }

  &.filled {
    background-color: $COLOR_ACTIVE;
  }

  &.filled:hover {
    background-color: $COLOR_ACTIVE_HOVER;
  }
}

@media only screen and (max-width: $BREAKPOINT_SM) {
  .actionLink {
    box-sizing: border-box;
    width: 100%;
    font-size: $FONT_SIZE_MD;
  }
}
