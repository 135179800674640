@import 'constants.scss';

.nav {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-wrap: wrap;
}

.navItem {
  color: black;
  font-size: $FONT_SIZE_LG;
  font-weight: bold;
  padding: $GUTTER_SM 0;
  text-decoration: none;
  text-transform: uppercase;
  margin-left: $GUTTER_MD;
  margin-right: $GUTTER_MD;
  margin-bottom: $GUTTER_LG;

  border-bottom: 3px solid transparent;
  transition: color $TRANSITION, border-color $TRANSITION;
  &.active {
    color: $COLOR_ACTIVE;
    border-color: $COLOR_ACTIVE;
  }
}
