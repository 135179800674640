@import 'constants.scss';

.footer {
  background-color: $COLOR_GRAY3;
  color: white;
  padding: $GUTTER_XXL;
  display: flex;
  flex-wrap: wrap;

  a {
    color: white;
    text-decoration: none;
    transition: color $TRANSITION_LONG;
  }
  a:hover {
    color: $COLOR_ACTIVE;
  }
}

.col {
  flex: 1;
  &:not(:last-child) {
    margin-right: $GUTTER_XXL;
  }
}

.title {
  font-size: $FONT_SIZE_LG;
  font-weight: bold;
  margin-bottom: $GUTTER_LG;
  text-transform: uppercase;
}

.emailInput {
  background: transparent;
  border: 1px solid white;
  box-sizing: border-box;
  color: white;
  display: block;
  font-family: $FONT_FAMILY;
  font-size: $FONT_SIZE_MD;
  padding: $GUTTER_SM $GUTTER_MD;
  margin: $GUTTER_LG 0 $GUTTER_MD 0;
  width: 100%;
  &::placeholder {
    color: $COLOR_GRAY;
  }
}

.emailButton {
  background: transparent;
  color: white;
  font-family: $FONT_FAMILY;
  font-size: $FONT_SIZE_MD;
  font-weight: bold;
  padding: $GUTTER_SM $GUTTER_LG;
  text-transform: uppercase;
}

.error {
  color: red;
  margin: $GUTTER_SM 0;
}

.success {
  color: green;
  margin: $GUTTER_SM 0;
}

.link {
  display: block;
}

.link,
.contactRow {
  &:not(:last-child) {
    margin-bottom: $GUTTER_SM;
  }
}

.contactIcon,
.socialLink svg {
  width: 20px;
  height: 20px;
}

.contactIcon {
  fill: white;
  margin-right: $GUTTER_SM;
}

.socialLink {
  &:not(:last-child) {
    margin-right: $GUTTER_SM;
  }

  svg {
    fill: white;
    transition: fill $TRANSITION_LONG;

    &:hover {
      fill: $COLOR_ACTIVE;
    }
  }
}

.contactRow {
  display: flex;
  align-items: center;
}

@media only screen and (max-width: $BREAKPOINT_SM) {
  .footer {
    display: block;
    padding: $GUTTER_XXL $GUTTER_MD;
  }
  .col {
    width: 100%;
    &:not(:last-child) {
      margin-bottom: $GUTTER_XXL;
    }
  }
  .emailButton {
    width: 100%;
  }
}
