@import 'constants.scss';

.reviewsPreview {
  background: linear-gradient(
    to bottom,
    white 0%,
    white 70%,
    $COLOR_ACTIVE 70%,
    $COLOR_ACTIVE 100%
  );
  padding: $GUTTER_XXL;
}

.reviews {
  margin: $GUTTER_XL;
  display: flex;
  justify-content: center;
}

.review {
  background-color: $COLOR_BG_LIGHT;
  flex: 1;
  padding: $GUTTER_XL;
  &:not(:last-child) {
    margin-right: $GUTTER_XL;
  }

  display: flex;
  flex-direction: column;
}

.quote {
  flex: 1;
  line-height: 1.4em;
  &::before {
    color: $COLOR_ACTIVE;
    content: '“';
    font-size: 50px;
    font-weight: bold;
    line-height: $FONT_SIZE_MD;
    position: relative;
    top: 9px;
  }
}

.author {
  &::before {
    content: '- ';
  }
  color: $COLOR_ACTIVE;
  font-weight: bold;
  margin-top: $GUTTER_MD;
  text-align: right;
}

.linkWrapper {
  text-align: center;
}

.frederickReviews {
  padding: $GUTTER_XL;
  max-width: 620px;
  margin: 0 auto;
}

@media only screen and (max-width: $BREAKPOINT_MD) {
  .reviews {
    margin: 0;
    display: block;
  }
  .review {
    box-sizing: border-box;
    width: 100%;
    margin-bottom: $GUTTER_XL;
  }
  .frederickReviews {
    padding: 0 0 $GUTTER_XL;
  }
}

@media only screen and (max-width: $BREAKPOINT_SM) {
  .reviewsPreview {
    padding: $GUTTER_XXL $GUTTER_MD;
  }
  .actionLink {
    font-size: $FONT_SIZE_MD;
  }
}