@import 'constants.scss';

.coach,
.social {
  margin-bottom: $GUTTER_LG;
  text-align: center;
}

.socialLink {
  display: inline-block;
  &:not(:last-child) {
    margin-right: $GUTTER_SM;
  }
}

.socialIcon {
  width: 40px;
  height: 40px;
  &:hover {
    fill: $COLOR_ACTIVE;
  }
}

.bio {
  font-size: $FONT_SIZE_SM;
}
