@import 'constants.scss';

.workoutPage {
  padding: $GUTTER_XXL;
  margin: 0 auto;
  max-width: $PAGE_WIDTH;
}

@media only screen and (max-width: $BREAKPOINT_SM) {
  .workoutPage {
    padding: $GUTTER_XXL $GUTTER_MD;
  }
}