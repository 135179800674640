@import 'constants.scss';

.navItem {
  display: block;
  color: black;
  font-size: $FONT_SIZE_LG;
  font-weight: bold;
  padding: $GUTTER_SM $GUTTER_MD $GUTTER_SM 0;
  text-decoration: none;
  text-transform: uppercase;
  transition: color $TRANSITION, border-color $TRANSITION;
  &.active {
    color: $COLOR_ACTIVE;
    border-color: $COLOR_ACTIVE;
  }
}

@media only screen and (min-width: $BREAKPOINT_SM + 1) {
  .row {
    display: flex;
  }

  .content {
    flex: 4;
  }

  .nav {
    flex: 1;
    margin-right: $GUTTER_XXL;
    text-align: right;
  }

  .navItem {
    &:not(:last-child) {
      margin-bottom: $GUTTER_XXL;
    }

    border-right: 3px solid transparent;
  }
}

@media only screen and (max-width: $BREAKPOINT_SM) {
  .nav {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;

    margin-bottom: $GUTTER_LG;
  }

  .navItem {
    padding: $GUTTER_SM 0;
    border-bottom: 3px solid transparent;
    margin: 0 $GUTTER_MD $GUTTER_LG;
  }
}
