@import 'constants.scss';


.strongLineContainer {
  position: relative;
  overflow: hidden;
}

.image {
  width: 300px;
  height: 200px;
  box-sizing: border-box;
  border: 1px solid $COLOR_LIGHT_GRAY;
  margin-bottom: $GUTTER_SM;
}

.newVideo {
    color: #FFF;
    font-weight: bold;
    font-size: $FONT_SIZE_MD;
    text-transform: uppercase;
    padding: $GUTTER_SM;
    border-top: 1px solid #FFF;
    border-bottom: 1px solid #FFF;
    transform: rotate(45deg);
    text-align: center;
    position: absolute;
    width: 320px;
    right: -130px;
    top: 18px;
    background-color: $COLOR_ACTIVE;
}

.link {
  text-decoration: inherit;
  color: inherit;
}

.date {
  color: $COLOR_ACTIVE;
  font-size: $FONT_SIZE_XS;
  font-weight: bold;
  text-transform: uppercase;
  margin-bottom: $GUTTER_SM;
}

.title {
  font-size: $FONT_SIZE_MD;
  font-weight: bold;
}
