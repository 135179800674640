@import 'constants.scss';

.layout {
  display: flex;
  margin: auto;
  max-width: $PAGE_WIDTH;
}

.image {
  max-width: 100%;
}

.imageCol {
  flex: 3;
}

.textCol {
  flex: 2;
}

.textCol {
  padding: $GUTTER_XL;
}

@media (max-width: $BREAKPOINT_SM) {
  .layout {
    flex-direction: column-reverse;
  }

  .textCol {
    padding: $GUTTER_XL 0;
  }
}