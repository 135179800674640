@import 'constants.scss';

.coaches {
  // Compensate for bottom/right margins of .coach
  margin: 0 (-$GUTTER_XXL) (-$GUTTER_XXL) 0;
  text-align: center;
}

.coach {
  animation: fadeIn $TRANSITION_LONG;
  cursor: pointer;
  display: inline-block;
  margin: 0 $GUTTER_XXL $GUTTER_XXL 0;
  max-width: 200px;
  text-align: center;
  vertical-align: top;
}
