@import 'constants.scss';

.layout {
  margin: auto;
  max-width: 900px; // TODO
}

.image {
  max-width: 100%;
}

.imageCol,
.textCol {
  flex: 1;
}

.textCol {
  padding: $GUTTER_XL;
}

@media (min-width: $BREAKPOINT_SM + 1) {
  .layout {
    display: flex;
  }
}

@media (max-width: $BREAKPOINT_SM) {
  .textCol {
    padding: $GUTTER_XL 0;
  }
}
