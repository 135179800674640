@import 'constants.scss';

.image {
  width: 200px;
  height: 200px;
  box-sizing: border-box;
  border-radius: 50%;
  border: 1px solid $COLOR_LIGHT_GRAY;
  margin-bottom: $GUTTER_LG;
}

.name {
  color: $COLOR_ACTIVE;
  font-size: $FONT_SIZE_LG;
  font-weight: bold;
  text-transform: uppercase;
}

.title {
  font-size: $FONT_SIZE_LG;
}
