@import 'constants.scss';

.termsSection {
  margin: $GUTTER_XXL;
}

.termsWrapper {
  margin: 0 auto;
  max-width: 900px;
}
