@import 'constants.scss';

.schedulePage {
  max-width: $PAGE_WIDTH;
  margin: $GUTTER_XXL auto;
  padding: 0 $GUTTER_XXL;
}

.hide {
    display: none;
}

@media only screen and (max-width: $BREAKPOINT_SM) {
  .schedulePage {
    padding: 0 $GUTTER_MD;
  }
}