@import 'constants.scss';

.packages {
  // Compensate for bottom/right margins of .package
  margin: 0 (-$GUTTER_XL) (-$GUTTER_XL) 0;
}

.package {
  animation: fadeIn $TRANSITION_LONG;
  background-color: $COLOR_GRAY;
  color: white;
  display: inline-block;
  margin: 0 $GUTTER_XL $GUTTER_XL 0;
  text-align: center;
  width: 250px;
}

.label {
  font-size: $FONT_SIZE_LG;
  font-weight: bold;
  padding: 32px 0;
  text-transform: uppercase;
}

.price {
  font-size: $FONT_SIZE_XXL;
  font-weight: bold;
  padding-bottom: $GUTTER_XL;
}

.buy {
  background-color: $COLOR_GRAY2;
  padding: $GUTTER_LG 0;
}

@media (max-width: $BREAKPOINT_SM) {
  .packages {
    margin: 0 $GUTTER_MD;
  }
  .package {
    width: 100%;
    box-sizing: border-box;
    margin: 0 0 $GUTTER_XL 0;
  }

  .buy {
    padding: $GUTTER_LG;
  }
}
