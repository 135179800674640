@import 'constants.scss';

.coldPlungeSection {
  max-width: $PAGE_WIDTH;
  margin: $GUTTER_XXL auto;
  text-align: center;
}

p.coldPlungeDescription {
  max-width: 800px;
  margin: 0 auto !important;
  text-align: left;
}

.button {
  margin-top: $GUTTER_XL;
}

@media only screen and (max-width: $BREAKPOINT_SM) {
  .coldPlungeSection {
    padding: 0 $GUTTER_MD;
  }
}