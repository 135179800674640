@import 'constants.scss';

.stronglineSection {
  margin: $GUTTER_XXL;
}

.nav {
  margin-bottom: $GUTTER_XL;
}

.modal {
  animation: fadeIn ease-in $TRANSITION_LONG both;
  z-index: $Z_INDEX_MODAL;

  // Covers the screen
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  background-color: rgba(0,0,0, 0.20);
}