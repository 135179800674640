@import 'constants.scss';

.introPage {
  margin: $GUTTER_XXL;
}

.section {
  &:not(:last-child) {
    margin-bottom: $GUTTER_XXL;
  }
}

.button {
  margin-top: $GUTTER_XL;
}

.row {
  display: flex;
  margin-top: $GUTTER_XXL;
  width: 100%;
  max-width: $PAGE_WIDTH;
}

.col {
  flex: 1;
  text-align: center;
}

.title {
  font-size: 34px;
  font-weight: bold;
  text-transform: uppercase;
}

.purchaseAction,
.newStudentAction {
  font-size: $FONT_SIZE_LG;
  margin-top: $GUTTER_XXL;
  padding: $GUTTER_LG $GUTTER_XL;
  color: white;
  display: inline-block;
  font-family: $FONT_FAMILY;
  font-weight: bold;
  text-decoration: none;
  text-transform: uppercase;
  text-align: center;
  transition: background-color $TRANSITION_LONG;
}

.purchaseAction {
  background-color: rgba($COLOR_ACTIVE, 0.9);

  &:hover {
    background-color: rgba($COLOR_ACTIVE_HOVER, 0.9) !important;
  }
}

.newStudentAction {
  background-color: rgba($COLOR_GRAY, 0.9) !important;

  &:hover {
    background-color: rgba($COLOR_GRAY2, 0.9) !important;
  }
}

@media (max-width: $BREAKPOINT_SM) {
  .introPage {
    margin: $GUTTER_XXL $GUTTER_MD;
  }

  .centerAlignMobile {
    text-align: center;
  }

  .row {
    display: block;
  }

  .col {
    &:not(:last-child) {
      margin-bottom: $GUTTER_XXL;
    }
  }

  .purchaseAction,
  .newStudentAction {
    margin-top: $GUTTER_LG;
    font-size: $FONT_SIZE_MD;
    padding: $GUTTER_MD;
    box-sizing: border-box;
  }

  .title {
    font-size: 28px;
  }
}
