@import 'constants.scss';

.heroContent {
  background-position: center;
  background-size: cover;
  box-sizing: border-box;

  font-size: $FONT_SIZE_XL;
  height: 90vh;
  color: white;
  padding: $GUTTER_MD;
  text-align: center;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &.mini {
    padding: $GUTTER_MD;
    height: 68vh;
  }
}

@media (max-width: $BREAKPOINT_SM) {
  .heroContent {
    font-size: 50px;

    &.mini {
      height: 80vh;
    }
  }
}
