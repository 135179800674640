@import 'constants.scss';

.header {
  text-align: center;
  font-size: 22px;
  line-height: 36px;
  letter-spacing: $LETTER_SPACING_LG;
  margin-bottom: 45px;
}

.inlineLink {
  color: $COLOR_ACTIVE;
  text-decoration: none;
  font-style: italic;
}

.actionLink {
  background-color: rgba($COLOR_ACTIVE, 0.9);
  font-size: 22px;
  margin-top: 0;
  padding: $GUTTER_LG $GUTTER_XL;
  color: white;
  width: 100%;
  display: inline-block;
  box-sizing: border-box;
  font-family: $FONT_FAMILY;
  font-weight: bold;
  text-decoration: none;
  text-transform: uppercase;
  text-align: center;
  transition: background-color $TRANSITION_LONG;
  &:hover {
    cursor: pointer;
    background-color: rgba($COLOR_ACTIVE_HOVER, 0.9) !important;
  }
}
