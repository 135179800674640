@import 'constants.scss';

p {
  line-height: $FONT_LINE_HEIGHT;
  margin: 0 0 $GUTTER_MD 0;
  &:last-of-type {
    margin: 0;
  }
}

h2 {
  color: #333333;
  font-weight: bold;
  font-size: 42px;
  margin: 0 0 $GUTTER_XXL 0;
  text-align: center;
  text-transform: uppercase;
}

h3 {
  font-size: 32px;
  font-weight: normal;
  margin: 0 0 $GUTTER_LG 0;
}

h4 {
  font-size: $FONT_SIZE_LG;
  font-weight: bold;
  margin: 0 0 $GUTTER_LG 0;
  text-transform: uppercase;
}

.user-text {
  white-space: pre-line;
}
