$BREAKPOINT_SM: 767px;
$BREAKPOINT_MD: 992px;
$COLOR_BG_LIGHT: #f2f2f2;
$COLOR_ACTIVE: #3fa3d2;
$COLOR_ACTIVE_HOVER: #2f73a2;
$COLOR_LIGHT_GRAY: #e1e1e1;
$COLOR_GRAY: #999999;
$COLOR_GRAY2: #666666;
$COLOR_GRAY3: #313131;
$FONT_FAMILY: 'Source Sans Pro', sans-serif;
$FONT_SIZE_XS: 12px;
$FONT_SIZE_SM: 14px;
$FONT_SIZE_MD: 18px;
$FONT_SIZE_LG: 20px;
$FONT_SIZE_XL: 60px;
$FONT_SIZE_XXL: 88px;
$FONT_LINE_HEIGHT: 1.5em;
$GUTTER_SM: 10px;
$GUTTER_MD: 16px;
$GUTTER_LG: 24px;
$GUTTER_XL: 45px;
$GUTTER_XXL: 60px;
$HEADER_HEIGHT: 110px;
$LETTER_SPACING_MD: 0.02em;
$LETTER_SPACING_LG: 0.05em;
$PAGE_WIDTH: 1200px;
$TRANSITION: 150ms;
$TRANSITION_LONG: 300ms;
$ZINDEX_NAV: 1;
$ZINDEX_NAV_MOBILE_MENU: 2;
$Z_INDEX_MODAL: 3;

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
