@import 'constants.scss';

// https://www.w3schools.com/howto/howto_css_loader.asp

.spinner {
  border: 16px solid $COLOR_LIGHT_GRAY;
  border-top: 16px solid $COLOR_GRAY;
  border-radius: 50%;
  width: 100px;
  height: 100px;
  margin: $GUTTER_XXL auto;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
